import React from 'react'

const GreenPromoLine = () => {
    // #05e00b
    return (
        <section className='bg_green promo_line'>
            <h3 style={{textAlign: `center`, color: `white`, fontWeight: `normal`}}>Олімп - фітнес мережа твоїх результатів</h3>
        </section >
    )
}

export default GreenPromoLine
