import React from "react";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Layout from "../components/Layout";
import SectionTitle from "../components/sections/SectionTitle";
import GreenPromoLine from "../components/sections/promoLine/GreenPromoLine";
import BreadCrumps from "../ui/breadCrumps/BreadCrumps";

const ContactPage = ({ data }) => {
  let isBlackBg = false;
  return (
    <Layout>
      <HelmetDatoCms seo={data.page.seo} favicon={data.site.favicon} />
      <BreadCrumps title={data.page.title} />
      <section id="clubs" className={isBlackBg ? "black_bg" : "light_bg"}>
        <SectionTitle
          isBlackBg={isBlackBg}
          title={data.page.sectiontitle}
          description={data.page.sectiondescription}
          url={false}
          urlBtnTxt={false}
        />
        <div className="about-text-wrapper">
          <div style={{ marginTop: `26px`, padding: `40px 20px` }}>
            {data.allclubs.edges.map(({ node: item }, index) => {
              return (
                <div key={index} style={{ marginBottom: `28px`, borderBottom: `1px solid #000` }}>
                  <h3>{item.title}</h3>
                  <br />
                  <p>{item.adress}</p>
                  <p>{item.phone}</p>
                  <br/>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <GreenPromoLine />
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    page: datoCmsContactpage {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      sectiondescription
      sectiontitle
    }
    allclubs: allDatoCmsClub {
      edges {
        node {
          title
          phone
          adress
        }
      }
    }
  }
`;


